<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div>
    <v-card :disabled="loading" :loading="loading">
      <v-container>
        <v-row no-gutters class="my-2">
          <v-col cols="12" sm="2">
            <v-autocomplete
              v-model="requestFilters.employeeUuid"
              label="Employees"
              :items="employees"
              :loading="loadingEmployees"
              :filter="
                (item, queryText) => {
                  return item.fullname
                    .toLowerCase()
                    .includes(queryText.toLowerCase());
                }
              "
              item-text="fullname"
              item-value="uuid"
              autocomplete="off"
              clearable
              hide-details
              outlined
              dense
              @change="getStatisticsSMS"
            >
              <template v-slot:item="data">
                <v-list-item-content>
                  <v-list-item-title
                    v-html="data.item.fullname"
                  ></v-list-item-title>
                  <v-list-item-subtitle
                    v-if="data.item.ocupation"
                    class="text-capitalize"
                    v-html="data.item.ocupation.name"
                  ></v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="2" class="px-1">
            <v-autocomplete
              v-model="requestFilters.leadUuid"
              label="Leads"
              :items="leadshow"
              item-text="fullname"
              item-value="uuid"
              hide-details
              outlined
              dense
              clearable
              @change="getStatisticsSMS"
            >
              <template v-slot:item="data">
                <v-list-item-content>
                  <v-list-item-title>{{
                    data.item.fullname
                  }}</v-list-item-title>
                  <v-list-item-subtitle
                    v-if="data.item.phone"
                    class="text-capitalize"
                    v-html="data.item.phone"
                  ></v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </v-col>

          <v-col cols="12" sm="2">
            <filter-by-clients
              :name="'Clients'"
              @selectC="setPatient($event)"
            />
          </v-col>

          <v-col cols="12" sm="2" class="px-1">
            <ma-date-picker v-model="dateFrom" label="From" past />
          </v-col>
          <v-col cols="12" sm="2">
            <ma-date-picker v-model="dateTo" label="To" past />
          </v-col>

          <v-col cols="12" sm="1">
            <template v-if="validClean">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    class="ml-2"
                    elevation="0"
                    small
                    rounded
                    depressed
                    fab
                    v-on="on"
                    v-bind="attrs"
                    @click="_clearFilters"
                  >
                    <v-icon>mdi-backup-restore</v-icon>
                  </v-btn>
                </template>
                <span>Clear Date filters</span>
              </v-tooltip>
            </template>
          </v-col>
          <v-col cols="12" sm="1">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  :loading="loading"
                  color="primary"
                  v-on="on"
                  text
                  @click="getStatisticsSMS"
                >
                  <v-icon>mdi-autorenew</v-icon>
                </v-btn>
              </template>
              <span>Refresh</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <div class="d-flex my-3 justify-end">
          <v-sheet color="white" elevation="1" height="80" rounded width="130">
            <template v-if="smscounts">
              <div class="text-center my-2">Incomming</div>
              <div class="text-center my-2">
                {{ smscounts.incomingsmss ? smscounts.incomingsmss : 0 }}
              </div>
            </template>
          </v-sheet>
          <v-sheet
            color="white"
            elevation="1"
            class="mx-2"
            height="80"
            rounded
            width="130"
          >
            <template v-if="smscounts">
              <div class="text-center my-2">Outgoing</div>
              <div class="text-center my-2">
                {{ smscounts.outgoingsms ? smscounts.outgoingsms : 0 }}
              </div>
            </template>
          </v-sheet>
          <v-sheet
            color="white"
            elevation="1"
            height="80"
            class="mr-2"
            rounded
            width="130"
          >
            <template v-if="smscounts">
              <div class="text-center my-2">Unread</div>
              <div class="text-center my-2">
                {{ smscounts.unreadsmss ? smscounts.unreadsmss : 0 }}
              </div>
            </template>
          </v-sheet>
          <v-sheet color="white" elevation="1" height="80" rounded width="130">
            <template v-if="smscounts">
              <div class="text-center my-2">Total</div>
              <div class="text-center my-2">{{ smscounts.totalsms }}</div>
            </template>
          </v-sheet>
        </div>
        <v-data-table
          :headers="headers"
          :loading="loading"
          :items="smsShow"
          hide-default-footer
          :footer-props="footerProps"
          :options.sync="options"
          @click:row="showDetails"
          class="elevation-1"
        >
        </v-data-table> </v-container
    ></v-card>
  </div>
</template>
<script>
import { getAPI } from "@/api/axios-base";
import { notifyError } from "@/components/Notification";
import MaDatePicker from "@/components/MaDatePicker/MaDatePicker.vue";
import moment from "moment";
import cleanObject from "@/utils/cleanObject";
import { mapActions, mapGetters, mapState } from "vuex";
import FilterByClients from "@/components/filters/FilterByClients.vue";
export default {
  name: "statistics-sms",
  components: { MaDatePicker, FilterByClients },
  data() {
    return {
      sms: [],
      smscounts: null,
      filterBy: "dob_name_lastname",
      loading: false,
      footerProps: {
        "items-per-page-options": [-1],
      },
      options: {},
      total: 0,
      headers: [
        {
          text: "Employee",
          align: "start",
          sortable: true,
          value: "fullname",
        },
        {
          text: "Incoming",
          align: "center",
          sortable: true,
          value: "incomingsmss",
        },
        {
          text: "Outgoing",
          align: "center",
          sortable: true,
          value: "outgoingsms",
        },

        {
          text: "Unread",
          align: "center",
          sortable: true,
          value: "unreadsmss",
        },
        {
          text: "Total",
          align: "center",
          sortable: true,
          value: "totalsms",
        },
      ],
      dateTo: "",
      dateFrom: "",
      filterRange: "none",
      requestFilters: {
        interval: {
          date1: null,
          date2: null,
        },
        employeeUuid: null,
        patientUuid: null,
        leadUuid: null,
      },
    };
  },
  watch: {
    dateFrom(val) {
      if (val !== null && this.dateTo !== null && this.dateTo !== "") {
        this.filterRange = "custom";
        this.getStatisticsSMS();
      }
    },
    dateTo(val) {
      if (val !== null && this.dateFrom !== null && this.dateFrom !== "") {
        this.filterRange = "custom";
        this.getStatisticsSMS();
      }
    },
  },
  computed: {
    ...mapGetters([
      "isSuper",
      "isAdmin",
      "isSupervisor",
      "getpatientN",
      "getUuid",
    ]),

    ...mapState("crmConfigurationsModule", ["leads"]),
    ...mapState("crmEmployeeModule", {
      loadingEmployees: "loading",
      employees: "workers",
    }),
    smsShow() {
      if (this.sms.length == 0) {
        return [];
      }
      const temp = this.sms;
      return temp;
    },
    validClean() {
      if (
        (this.dateFrom != null &&
          this.dateFrom != undefined &&
          this.dateFrom != "") ||
        (this.dateTo != null && this.dateTo != undefined && this.dateTo != "")
      ) {
        return true;
      }
      return false;
    },

    leadshow() {
      if (this.leads.length == 0) {
        return [];
      }
      const arr = this.leads.map((l) => {
        return { ...l, fullname: l.name + " " + l.last_name };
      });
      return arr;
    },
  },
  methods: {
    ...mapActions("crmEmployeeModule", ["actGetEmployees"]),

    ...mapActions("crmConfigurationsModule", ["actGetLeads"]),
    setPatient(val) {
      this.requestFilters.patientUuid = val;
      this.getStatisticsSMS();
    },
    getStatisticsSMS() {
      this.sms = [];
      let body = Object.assign({}, this.requestFilters);
      if (this.filterRange == "none") {
        delete body.interval;
      } else {
        body.interval = {
          date1: moment(this.dateFrom).startOf("day").utc().toISOString(),
          date2: moment(this.dateTo).endOf("day").utc().toISOString(),
        };
      }
      body = cleanObject(body);
      this.loading = true;
      /* getAPI
        .post("/reports/summary-sms", body)
        .then((res) => {
          this.loading = false;
          this.smscounts = res.data;
        })
        .catch((err) => {
          this.loading = false;
          let mess = err.response.data.message.toString();
          notifyError(err.response.data, `An error occurred: ${mess}`);
        }); */
      getAPI
        .post("/reports/summary-sms-employee", body)
        .then((res) => {
          this.loading = false;
          this.smscounts = res.data.find((r) => r.fullname == "TOTAL");
          this.sms = res.data.filter((r) => r.fullname != "TOTAL");
        })
        .catch((err) => {
          this.loading = false;
          let mess = err.response.data.message.toString();
          notifyError(err.response.data, `An error occurred: ${mess}`);
        });
    },

    _clearFilters() {
      if (this.dateFrom != "" && this.dateTo != "") {
        this.dateFrom = "";
        this.dateTo = "";
        this.filterRange = "none";
        this.getStatisticsSMS();
      } else {
        this.dateFrom = "";
        this.dateTo = "";
        this.filterRange = "none";
      }
    },

    showDetails(sms) {
      localStorage.setItem("uuidemployee", sms.uuid);
      this.$router.push(`/admin-sms`);
    },
  },
  async mounted() {
    this.getStatisticsSMS();

    await this.actGetLeads({ interval: { limit: 1000, offset: 0 } });
    await this.actGetEmployees();
  },
};
</script>
<style lang="scss" scoped></style>
